import { nookal } from '../services/nookal.js';
import ReactGA from "react-ga4";

export class BIM {

    constructor() {
        this.methods = nookal;
        this.GAId = "";
    }

    customStyles(){
        let styles = ".appointment-step-container{ background: #1c1d28; }"
            + ".time-consultation-btn.selected{ background: #1c1d28; }"
            + ".continue-button,.back-button{ background: #e30032 }"
            + ".appointment-list-list-top .time, .check_date_list_container-time{ background: #1e5e72 }"
            + ".booking-confirmation-wrapper{ max-width: 600px; }"
            + ".time{ background: " + this.colorScheme().secondary + "!important }"
            + ".check_date_list_container-time{ background: " + this.colorScheme().secondary + "!important }"
            + ".date_list_container:hover { color: " + this.colorScheme().secondary + "; border-color: " + this.colorScheme().secondary + " }"
            + '.more_times_text:hover, .more_times_text-icon:hover { color: ' + this.colorScheme().secondary + ' !important }'    
        return styles;
    }

    colorScheme() {
        return { 
            primary: '#1c1d28',
            secondary: '#e30032',
            secondarySelected: '#4b5054'
        }
    }

    bookingCompleteEvents() {
        ReactGA.initialize(this.GAId);
        
        // Send GA4 Event
        ReactGA.event('generate_lead', {
            action: 'Booking Complete',
            label: 'Booking Complete',
            lead_type: "Booking Complete"
        });
    
        console.log("Booking created event sent", this.GAId);
    }

}